import styled from "styled-components";

export const Text = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 24px;
  font-size: 14px;
  ol {
    list-style-type: decimal;
  }
  li {
    margin-left: 30px !important;
  }
`;

import React from "react";
import { Input } from "../../../../../../../Components/Input";

import { Col, Row, Separator } from "../../../../../../../styles";
import { translate } from "../../../../../../../utils/globalFunctions";

export function SimulationData({
  input,
  setInput,
  animalProfiles,
  feedRestrictionPrograms,
  environmentVariables,
  profile,
}) {
  return (
    <>
      <Row>
        <Col end={true}>
          <Input
            type="select"
            label={translate("Animal Profile", profile.language)}
            item={input}
            setItem={setInput}
            params="animalProfile"
            placeholder={translate(
              "Select the Animal Profile",
              profile.language
            )}
            required={true}
            options={animalProfiles
              .filter(({ customer }) =>
                input.customer ? customer === input.customer || !customer : true
              )
              .map(({ _id, nome }) => ({ value: _id, label: nome }))}
          />
        </Col>
        {/*     <Col end={true}>
          <Input
            type="select"
            label={translate("Feed Restriction Program", profile.language)}
            item={input}
            setItem={setInput}
            params="feedRestrictionProgram"
            placeholder={translate(
              "Select the Feed Restriction Program",
              profile.language
            )}
            options={feedRestrictionPrograms
              .filter(({ customer }) =>
                input.customer ? customer === input.customer || !customer : true
              )
              .map(({ _id, nome }) => ({ value: _id, label: nome }))}
          />
        </Col> */}
        <Col end={true}>
          <Input
            type="select"
            label={translate("Environment", profile.language)}
            item={input}
            setItem={setInput}
            params="environment"
            placeholder={translate("Select the Environment", profile.language)}
            required={true}
            options={environmentVariables
              .filter(({ customer }) =>
                input.customer ? customer === input.customer || !customer : true
              )
              .map(({ _id, nome }) => ({ value: _id, label: nome }))}
          />
        </Col>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              "Average weight of birds at 18 weeks (kg)",
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="Weight18"
            required={true}
          />
        </Col>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(`CV of BW`, profile.language)}
            item={input}
            setItem={setInput}
            params="CV18w"
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(`Number of Hens`, profile.language)}
            item={input}
            setItem={setInput}
            params="nHens"
            required={true}
          />
        </Col>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              `Age of hen at start of simulation(Week)`,
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="AgeStart"
            required={true}
          />
        </Col>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              `Age of hen at finish of simulation(Week)`,
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="FinalAge"
            required={true}
          />
        </Col>
      </Row>
      <Separator />
      <Row>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              `Initial light in rearing period(Hour)`,
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="Phorea"
            required={true}
          />
        </Col>

        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              `Final light in rearing period(Hour)`,
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="Phofi"
            required={true}
          />
        </Col>
        <Col end={true}>
          <Input
            type="input"
            inputType="number"
            label={translate(
              `Starting of the lighting program at production phase (age in weeks)`,
              profile.language
            )}
            item={input}
            setItem={setInput}
            params="Phocha"
            required={true}
          />
        </Col>
      </Row>
    </>
  );
}

import React from "react";
import { Button } from "../../../../../../Components/Button";
import { FontAwesome } from "../../../../../../Components/FontAwesome";
import { Input } from "../../../../../../Components/Input";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../Components/Table/style";
import { ButtonContent, Col, Row, Separator } from "../../../../../../styles";
import {
  convertNumberToString,
  translate,
} from "../../../../../../utils/globalFunctions";
import { InputTable } from "./style";

export function InputData({
  customers,
  simulations,
  input,
  setInput,
  profile,
  optimize,
  dietComponents,
}) {
  const simulationHandler = (e) => {
    const { value } = e.target;
    setInput((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.data.diets = simulations
        .filter(({ _id }) => _id === value)[0]
        .dietProgram.dietas.map(() => ({ min: "", max: "" }));
      newState.data.simulation = value;
      console.log(newState);
      return newState;
    });
  };

  const _30DozensHandler = (value, param) => {
    setInput((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.price[`${param}30d`] = value;
      newState.price[`${param}1d`] = Math.round((+value / 30) * 100) / 100;
      newState.price[`${param}PerEgg`] =
        Math.round((+value / 30 / 12) * 100) / 100;
      return newState;
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            type="select"
            item={input}
            setItem={setInput}
            params="data.customer"
            label={translate("Customer", profile.language)}
            placeholder={translate("Select the customer", profile.language)}
            options={customers.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))}
          />
        </Col>

        <Col>
          <Input
            type="select"
            onChange={simulationHandler}
            value={input.data.simulation}
            required
            label={translate("Simulation", profile.language)}
            placeholder={translate("Select the Simulation", profile.language)}
            options={simulations
              .filter(({ customer }) =>
                input.data.customer
                  ? customer === input.data.customer || !customer
                  : true
              )
              .filter(
                ({ simulationType }) => simulationType === "feedFormulator"
              )
              .map(({ _id, nome }) => ({ value: _id, label: nome }))}
          />
        </Col>
        <Col>
          <Input
            type="select"
            item={input}
            setItem={setInput}
            params="data.dComponent"
            required
            label={translate("Diet Component", profile.language)}
            placeholder={translate(
              "Select the Diet Component",
              profile.language
            )}
            options={Object.entries(dietComponents).map(([value, label]) => ({
              value,
              label,
            }))}
          />
        </Col>
        <Col>
          <Input
            type="input"
            item={input}
            setItem={setInput}
            params="name"
            required
            label={translate("Optimization Name", profile.language)}
          />
        </Col>
      </Row>
      <Separator />
      {input.data.simulation && input.data.dComponent ? (
        <>
          <TableContent className="table-center table-no-padding">
            <Header>
              <tr>
                <th>{translate("Diets", profile.language)}</th>
                <th>{translate("End Age", profile.language)}</th>
                <th>{dietComponents[input.data.dComponent]}</th>
                <th>{translate("Minimum", profile.language)}</th>
                <th>{translate("Maximum", profile.language)}</th>
              </tr>
            </Header>
            <Body>
              {simulations
                .filter(({ _id }) => input.data.simulation === _id)[0]
                .dietProgram.dietas.map(({ dieta, value, fim }, index) => (
                  <tr>
                    <td>{dieta.nome}</td>
                    <td>
                      {fim
                        ? translate("End of Program", profile.language)
                        : value}
                    </td>
                    <td>
                      {input.data.dComponent === "energiaMetAves"
                        ? convertNumberToString(
                            dieta.composicao.filter(
                              ({ nomeDB }) => nomeDB === "energiaMetAves"
                            )[0].valor * 1000,
                            0
                          )
                        : convertNumberToString(
                            dieta.aminoacidos.filter(
                              ({ nomeDB }) => nomeDB === "lisinaDig"
                            )[0].valor,
                            3
                          )}
                    </td>
                    <td>
                      <InputTable
                        inputType="number"
                        type="inputOnly"
                        placeholder="(%)"
                        item={input}
                        setItem={setInput}
                        params={`data.diets.${index}.min`}
                        required
                      />
                    </td>
                    <td>
                      <InputTable
                        inputType="number"
                        type="inputOnly"
                        placeholder="(%)"
                        item={input}
                        setItem={setInput}
                        params={`data.diets.${index}.max`}
                        required
                      />
                    </td>
                  </tr>
                ))}
            </Body>
          </TableContent>
        </>
      ) : null}

      <TableContent className="table-center table-no-padding">
        <Header>
          <tr>
            <th colSpan={4}>
              {translate("Economic values ($)", profile.language)}
            </th>
          </tr>
          <tr>
            <th style={{ width: 145 }}></th>
            <th>{translate("30 dozen", profile.language)}</th>
            <th>{translate("1 dozen", profile.language)}</th>
            <th>{translate("Per egg", profile.language)}</th>
          </tr>
        </Header>
        <Body>
          <tr>
            <th>{translate("Small", profile.language)}</th>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                value={input.price.small30d}
                onChange={(e) => _30DozensHandler(e.target.value, "small")}
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.small1d"
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.smallPerEgg"
                required
              />
            </td>
          </tr>
          <tr>
            <th>{translate("Medium", profile.language)}</th>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                value={input.price.medium30d}
                onChange={(e) => _30DozensHandler(e.target.value, "medium")}
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.medium1d"
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.mediumPerEgg"
                required
              />
            </td>
          </tr>
          <tr>
            <th>{translate("Large", profile.language)}</th>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                value={input.price.large30d}
                onChange={(e) => _30DozensHandler(e.target.value, "large")}
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.large1d"
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.largePerEgg"
                required
              />
            </td>
          </tr>
          <tr>
            <th>{translate("X-Large", profile.language)}</th>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                value={input.price.xLarge30d}
                onChange={(e) => _30DozensHandler(e.target.value, "xLarge")}
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.xLarge1d"
                required
              />
            </td>
            <td>
              <InputTable
                inputType="number"
                type="inputOnly"
                placeholder="($)"
                item={input}
                setItem={setInput}
                params="price.xLargePerEgg"
                required
              />
            </td>
          </tr>
        </Body>
      </TableContent>
      <Separator style={{ marginTop: 20 }} />
      <ButtonContent>
        <Button
          type="button"
          onClick={optimize}
          bg="default"
          border="default"
          color="white"
          style={{ width: "auto" }}
        >
          {translate("Optimize", profile.language)}
          &nbsp;{" "}
          <FontAwesome
            type="solid"
            name="gears"
            size="12"
            color="white"
            noClick={optimize}
          />
        </Button>
      </ButtonContent>
    </>
  );
}

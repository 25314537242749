import React from "react";
import { Input } from "../../../../../../../Components/Input";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../Components/Table/style";
import { Col, Row } from "../../../../../../../styles";
import { translate } from "../../../../../../../utils/globalFunctions";
import { Td } from "../../style";

export function FeedFormulatorInput({
  input,
  setInput,
  dietPrograms,
  dietConfig,
  profile,
}) {
  const dietProgramHandler = (e) => {
    const { value } = e.target;
    setInput((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.dietProgram = value;
      if (value) {
        newState.diet = dietPrograms
          .filter(({ _id }) => _id === value)[0]
          .dietas.map(({ dieta, fim, value }) => ({
            nome: dieta.nome,
            ingredients: [""],
            value,
            fim,
          }));
      } else {
        newState.diet = [{ ingredients: [""] }];
      }
      console.log(newState);
      return newState;
    });
  };

  return (
    <>
      <Row>
        <Col style={{ marginBottom: 0 }}>
          <Input
            type="select"
            label={translate("Diet Program", profile.language)}
            onChange={dietProgramHandler}
            value={input.dietProgram}
            placeholder={translate("Select the Diet Program", profile.language)}
            required={true}
            options={dietPrograms
              .filter(({ customer }) =>
                input.customer ? customer === input.customer || !customer : true
              )
              .map(({ _id, nome }) => ({ value: _id, label: nome }))}
          />
        </Col>
        <Col />
      </Row>
      {input.dietProgram && (
        <TableContent>
          <Header>
            <tr>
              <th style={{ width: 250 }}>
                {translate("Parameters", profile.language)}
              </th>
              {input.diet.map((item, index) => (
                <th>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.nome}
                  </div>
                </th>
              ))}
            </tr>
          </Header>
          <Body>
            {dietConfig.map(({ label, key }) => (
              <tr key={key}>
                <Td>{translate(label, profile.language)}</Td>
                {input.diet.map(({ fim, value }, index) => (
                  <td
                    style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 15 }}
                    key={key + index}
                  >
                    {fim ? input.FinalAge : value}
                  </td>
                ))}
              </tr>
            ))}
          </Body>
        </TableContent>
      )}
    </>
  );
}

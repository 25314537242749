import React, { useContext } from "react";
import { FontAwesome } from "../../../../Components/FontAwesome";
import { SubHeader } from "../../../../Components/SubHeader";
import Profile from "../../../../contexts/profile";
import { Block, BlockBody } from "../../../../styles";
import { translate } from "../../../../utils/globalFunctions";
import { Text } from "./style";

export function Home(props) {
  const { profile } = useContext(Profile);
  return (
    <>
      <SubHeader
        {...props}
        title={translate("Home", profile.language)}
        route="simulator"
        breadcrumbs={[{ label: translate("Home", profile.language) }]}
        icon={
          <FontAwesome type="solid" name="area-chart" size={15} color="text" />
        }
      />
      <Block>
        <BlockBody
          style={{
            backgroundImage:
              "url(https://www.poultrymodel.com/static/media/home.64ee181a.png)",
            backgroundSize: "cover",
            backgroundPosition: "50%",
            paddingBottom: 50,
          }}
        >
          {profile.language === "en" || !profile.language ? (
            <>
              <h2>Introduction</h2>
              <Text>
                The EPM is a software to simulate the egg production of
                commercial laying hens in a diverse production scenario. Apart
                from egg production, the requirement for energy and essential
                amino acids are also estimated.
              </Text>
              <h2>Objective</h2>
              <Text>
                Offer a tool for a decision-making process. The nutritionist can
                test many possibilities before implementing in the field,
                contributing to the farm organization and the economic return.
              </Text>
              <h2>Basic entries for a simple simulation:</h2>
              <Text>
                <ol>
                  <li>Access the option “Basic Registration”.</li>
                  <li>
                    Access the option “Environment Variables” and access “New
                    Environment Variables” to register.
                  </li>
                  <li>Access the option “Simulator” {">"} “New Simulation”.</li>
                  <li>
                    At the option “Select the type of simulation” select “Input
                    Nutrients” or “Input Ingredients”. Enter the obligatory
                    items * and select the button “Simulate”.
                  </li>
                </ol>
              </Text>
              <h2>Observations:</h2>
              <Text>
                <ul>
                  <li>
                    The simulations saved using the options described in the
                    item 4 above, will not be loaded in the optimization tool.
                    For that, it is necessary to use the option “Feed
                    formulator”.
                  </li>
                  <li>
                    The program have many possibilities that can be consulted at
                    this{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://zenbytes-public.sfo2.digitaloceanspaces.com/unesp/Manual%20Egg%20Production%20Model_English.pdf"
                    >
                      link
                    </a>
                    .
                  </li>
                </ul>
              </Text>
            </>
          ) : null}
          {profile.language === "pt" && (
            <>
              <h2>Introdução</h2>
              <Text>
                O EPM é um software para simulação da produção de ovos em
                poedeiras comerciais em diversos cenários de produção. Além da
                produção de ovos, também são estimadas as exigências diárias de
                energia e aminoácidos essenciais.
              </Text>
              <h2>Objetivo</h2>
              <Text>
                Fornecer uma ferramenta para auxiliar na tomada de decisão. O
                nutricionista poderá testar diversas possibilidades auxiliando
                no planejamento da granja e contribuindo para maior retorno
                econômico da atividade.
              </Text>
              <h2>Entradas básicas para uma simulação simples:</h2>
              <Text>
                <ol>
                  <li>Acessar a opção “Registro básico”.</li>
                  <li>
                    Acessar a opção “Variáveis de Ambiente” e acessar o botão
                    “Nova Variáveis de Ambiente” e fazer o cadastro.
                  </li>
                  <li>Acessar a opção “Simulador” {">"} “Nova Simulação”.</li>
                  <li>
                    Na opção “Selecione o tipo de simulação” selecione “Entrada
                    de nutrientes” ou “Entrada de ingredientes”. Preencher os
                    demais itens obrigatórios * e selecionar o botão “Simular”.
                  </li>
                </ol>
              </Text>
              <h2>Observações:</h2>
              <Text>
                <ul>
                  <li>
                    Simulações realizadas utilizando a opção conforme o item 4
                    acima, não estarão disponíveis na ferramenta de otimização.
                    Para isso é necessário utilizar a opção “Formulador de
                    rações”.
                  </li>
                  <li>
                    O programa possui muitas opções que podem ser consultadas no
                    manual do usuário neste{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://zenbytes-public.sfo2.digitaloceanspaces.com/unesp/Manual%20Egg%20Production%20Model_Portugues.pdf"
                    >
                      link
                    </a>
                    .
                  </li>
                </ul>
              </Text>
            </>
          )}
          {profile.language === "es" && (
            <>
              <h2>Introducción</h2>
              <Text>
                El software EPM es un programa que permite simular la producción
                de huevos que puede aplicarse en diversos ámbitos de la
                producción comercial de aves. Nutricionalmente, el programa
                permite estimar los requerimientos diarios de energía y
                aminoácidos.
              </Text>
              <h2>Objetivo</h2>
              <Text>
                Brindar a los nutricionistas una herramienta que auxilie en la
                toma de decisiones. El nutricionista podrá probar diversas
                situaciones nutricionales y de manejo sirviendo como herramienta
                de proyección de las granjas contribuido con un mayor retorno
                económico.
              </Text>

              <h2>Registro de datos para una simulación básica:</h2>
              <Text>
                <ol>
                  <li>Acceder a la opción “Registro básico”.</li>
                  <li>
                    Acceder a la opción “Variables ambientales” y dar clic en el
                    botón “Registrar nuevas variables ambientales” y rellenar
                    los campos solicitados.
                  </li>
                  <li>
                    Acceder a la opción “Simulador” luego clic en “Nueva
                    Simulación”.
                  </li>
                  <li>
                    En “Seleccione el tipo de simulación” elija o “Entrada de
                    nutrientes” o “Entrada de ingredientes”. Rellenar los demás
                    campos obligatorios (*) y dar clic en “Simular”.
                  </li>
                </ol>
              </Text>
              <h2>Observaciones:</h2>
              <Text>
                <ul>
                  <li>
                    Las simulaciones realizadas usando la opción descrita en el
                    ítem 4 no estarán disponibles en la herramienta de
                    “optimización” ya que para ello se necesita activar la
                    opción “Formulador de raciones”.
                  </li>
                  <li>
                    Las diversas funciones que el programa posee pueden ser
                    consultadas en el siguiente{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://zenbytes-public.sfo2.digitaloceanspaces.com/unesp/Manual%20Egg%20Production%20Model_Espanol.pdf"
                    >
                      link
                    </a>
                    .
                  </li>
                </ul>
              </Text>
            </>
          )}
        </BlockBody>
      </Block>
    </>
  );
}

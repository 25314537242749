import { useState } from "react";
import { useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "../../../../../../Components/Button";
import { Input } from "../../../../../../Components/Input";
import { SubHeader } from "../../../../../../Components/SubHeader";
import Profile from "../../../../../../contexts/profile";
import { api } from "../../../../../../services/api";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../../styles";
import { translate } from "../../../../../../utils/globalFunctions";

import { useNavigate, useParams } from "react-router-dom";
import { FontAwesome } from "../../../../../../Components/FontAwesome";

export function CreateEditAnimalProfile(props) {
  const { profile } = useContext(Profile);

  const params = useParams();

  const navigate = useNavigate();

  const [animalProfile, setAnimalProfile] = useState({
    nome: "",
    Maintenance_m: "",
    ProteinDepRatio_m: "",
    ProteinMaturity_m: "",
    LipidProteinMat_m: "",
    LipidMaturity_m: "",
    FeatherProteinDepRatio_m: "",
    FeatherProteinMaturity_m: "",
    OvaryProteinDepRatio_m: "",
    OvaryProteinMaturity_m: "",
    OviductProteinDepRatio_m: "",
    OviductProteinMaturity_m: "",
    OvaryLipidDepRatio_m: "",
    OvaryLipidMaturity_m: "",
    OviductLipidDepRatio_m: "",
    OviductLipidMaturity_m: "",
    aWater_m: "",
    bWater_m: "",
    aAsh_m: "",
    bAsh_m: "",
    BodyProteinMaxGain_m: "",
    FeatherProteinMaxGain_m: "",
    LipidDepRatio_m: "",
    LipidMaxGain_m: "",
    customer: null,
    status: true,
  });

  const [getAnimalProfile, setGetAnimalProfile] = useState(true);

  const saveAnimalProfile = async (e) => {
    try {
      e.preventDefault();
      const query = JSON.parse(JSON.stringify(animalProfile));
      if (query._id) {
        delete query._id;
      }
      const response = await Swal.fire({
        title: translate("Create Animal Profile", profile.language),
        text: translate(
          "Do you want to confirm Animal Profile creation?",
          profile.language
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        cancelButtonText: translate("Cancel", profile.language),
        confirmButtonText: translate(
          "Yes, Create Animal Profile",
          profile.language
        ),
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("animalprofile", query).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        if (response.value.err) {
          return Swal.fire(
            translate("Save Animal Profile", profile.language),
            translate(response.value.data.message, profile.language),
            "error"
          );
        }
        Swal.fire(
          translate("Create Animal Profile", profile.language),
          translate(response.value.data.message, profile.language),
          response.value.err ? "error" : "success"
        );
        navigate(
          "/basicregistration/animalprofile/edit/" + response.value.data.id,
          {
            replace: true,
          }
        );
        setGetAnimalProfile(true);
      }

      /*  if (!params.id) {
        
      } else {
        const response = await Swal.fire({
          title: translate("Edit Animal Profile", profile.language),
          text: translate(
            "Do you want to confirm Animal Profile edit?",
            profile.language
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          cancelButtonText: translate("Cancel", profile.language),
          confirmButtonText: translate(
            "Yes, Edit Animal Profile",
            profile.language
          ),
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api.put("animalprofile/" + params.id, query).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
        });
        if (response.value) {
          Swal.fire(
            translate("Edit Animal Profile", profile.language),
            translate(response.value.data.message, profile.language),
            response.value.err ? "error" : "success"
          );
          setGetAnimalProfile(true);
        }
      } */
    } catch (e) {
      Swal.fire(
        translate("Edit Animal Profile", profile.language),
        translate("Error saving Animal Profile", profile.language),
        "error"
      );
      setGetAnimalProfile(true);
    }
  };

  useEffect(() => {
    const loadAnimalProfile = async () => {
      if (params.id) {
        const responseAnimalProfile = await api
          .get(`animalprofile/${params.id}`)
          .catch(() => ({ data: false }));
        if (!responseAnimalProfile.data) {
          return Swal.fire(
            translate("Edit Animal Profile", profile.language),
            translate("Error to search AnimalProfiles", profile.language),
            "error"
          );
        }
        setAnimalProfile(responseAnimalProfile.data);
      }
    };
    if (getAnimalProfile) {
      setGetAnimalProfile(false);
      loadAnimalProfile();
    }
  }, [getAnimalProfile, params, navigate, profile]);

  return (
    <>
      <SubHeader
        {...props}
        title={translate("Animal Profile", profile.language)}
        breadcrumbs={[
          { label: translate("Basic Registration", profile.language) },
          {
            label: translate("Animal Profile List", profile.language),
            to: "/basicregistration/animalprofile/1/30/index/{}",
          },
          {
            label: translate(
              (params.id ? "Edit " : "Create ") + "Animal Profile",
              profile.language
            ),
          },
        ]}
        icon={
          <FontAwesome type="solid" name="kiwi-bird" size="24" color="text" />
        }
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          {translate("Animal Profile", profile.language)}
        </BlockHeader>
        <BlockBody>
          <form onSubmit={(e) => saveAnimalProfile(e)}>
            <Row>
              <Col end={true}>
                <Input
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="nome"
                  required
                  label={translate("Animal Profile Name", profile.language)}
                />
              </Col>
              <Col end={true}>
                <Input
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params={`customer`}
                  type="autocomplete"
                  label={translate("Customer", profile.language)}
                  paramsGet={["name"]}
                  paramsLabel={["name"]}
                  select={"name"}
                  model={"customer"}
                  placeholder={translate(
                    "Type the customer name",
                    profile.language
                  )}
                />
              </Col>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="Maintenance_m"
                  required
                  label={translate("Maintenance", profile.language)}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="ProteinDepRatio_m"
                  required
                  label={translate("Protein dep. Ratio (d)", profile.language)}
                />
              </Col>
            </Row>
            <Row>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="ProteinMaturity_m"
                  required
                  label={translate("Maturity Protein (g)", profile.language)}
                />
              </Col>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="LipidProteinMat_m"
                  required
                  label={translate(
                    "Lipid / Protein ratio at Maturity",
                    profile.language
                  )}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="LipidMaturity_m"
                  required
                  label={translate("Maturity Lipid", profile.language)}
                />
              </Col>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="FeatherProteinDepRatio_m"
                  required
                  label={translate(
                    "Feather Protein dep. Ratio (d)",
                    profile.language
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="FeatherProteinMaturity_m"
                  required
                  label={translate(
                    "Feather Maturity Protein (g)",
                    profile.language
                  )}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="aWater_m"
                  required
                  label={translate(
                    "Coef. a (Water / Protein)",
                    profile.language
                  )}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="bWater_m"
                  required
                  label={translate(
                    "Coef. b (Water / Protein)",
                    profile.language
                  )}
                />
              </Col>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="aAsh_m"
                  required
                  label={translate("Coef. a (Ash / Protein)", profile.language)}
                />
              </Col>
            </Row>
            <Row>
              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="bAsh_m"
                  required
                  label={translate("Coef. b (Ash / Protein)", profile.language)}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="BodyProteinMaxGain_m"
                  required
                  label={translate(
                    "Day at Max. Body Protein Gain (d)",
                    profile.language
                  )}
                />
              </Col>

              <Col end={true}>
                <Input
                  inputType="number"
                  type="input"
                  item={animalProfile}
                  setItem={setAnimalProfile}
                  params="FeatherProteinMaxGain_m"
                  required
                  label={translate(
                    "Day at Max. Feather Protein  Gain (d)",
                    profile.language
                  )}
                />
              </Col>
              <Col end={true} />
            </Row>

            <Separator />
            <Row>
              <Col end={true} style={{ alignItems: "center" }}>
                <Button
                  notFull
                  type="submit"
                  bg="default"
                  border="default"
                  color="white"
                >
                  {translate("Save", profile.language)}
                  &nbsp;
                </Button>
              </Col>
            </Row>
          </form>
        </BlockBody>
      </Block>
    </>
  );
}

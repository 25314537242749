import { Tab, Tabs } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Loading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesome } from "../../../../../Components/FontAwesome";
import { SubHeader } from "../../../../../Components/SubHeader";
import Profile from "../../../../../contexts/profile";
import { api } from "../../../../../services/api";
import { Block, BlockBody } from "../../../../../styles";
import {
  convertNumberToString,
  translate,
} from "../../../../../utils/globalFunctions";
import { InputData } from "./InputData";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Result } from "./Result";
import { Button } from "../../../../../Components/Button";
import Swal from "sweetalert2";

export function CreateEditOptimization(props) {
  const initialInput = {
    name: "",
    data: {
      simulation: "",
      customer: "",
      dComponent: "",
      flockOut: "",
      diets: [],
    },
    price: {
      small30d: "",
      small1d: "",
      smallPerEgg: "",
      medium30d: "",
      medium1d: "",
      mediumPerEgg: "",
      large30d: "",
      large1d: "",
      largePerEgg: "",
      xLarge30d: "",
      xLarge1d: "",
      xLargePerEgg: "",
    },
  };

  const { profile } = useContext(Profile);

  const dietComponents = {
    lisinaDig: translate("Digestible Lysine (%)", profile.language),
    relIdeal: translate("Balanced Protein (%)", profile.language),
    energiaMetAves: translate(
      "Metabolizable Energy (kcal/kg)",
      profile.language
    ),
  };

  const params = useParams();

  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(true);

  const [tab, setTab] = useState(0);

  const [customers, setCustomers] = useState([]);

  const [simulations, setSimulations] = useState([]);

  const [response, setResponse] = useState({
    ...initialInput,
    optimization: {},
  });

  const [input, setInput] = useState(initialInput);

  const [getData, setGetData] = useState(true);

  const [responseTimeMs, setResponseTimeMs] = useState("");

  const [progress, setProgress] = useState(0);

  const [progressTime, setProgressTime] = useState(0);

  const [aguarde, setAguarde] = useState(false);

  const [otimizado, setOtimizado] = useState(false);

  const [composicao, setComposicao] = useState([]);

  const [aminoacidos, setAminoacidos] = useState([]);

  const [ingredients, setIngredients] = useState([]);

  const optimize = async () => {
    try {
      setAguarde(true);
      setProgress(0);
      setProgressTime("");
      const responseFormulas = await api.post("optimizearray", input);
      if (!responseFormulas.data.variacoes[0]) {
        setAguarde(false);
        Swal.fire(
          translate("Optimization", profile.language),
          translate(
            "There were no viable feed in the minimum and/or maximum values entered.",
            profile.language
          ),
          "error"
        );
        return;
      }
      const simulacoes = responseFormulas.data;
      const resultados = [];
      for (let i = 0; i < simulacoes.variacoes.length; i++) {
        const simulation = {
          ...input,
          diets: [],
        };
        simulacoes.variacoes[i].forEach(function (item, index) {
          simulation.diets.push(simulacoes.pDieta[index].dietas[item]);
        });
        const sendDate = new Date().getTime();
        const res = await api.post("otimizacao", simulation);
        const receiveDate = new Date().getTime();

        setResponseTimeMs(responseTimeMs + (receiveDate - sendDate));
        const media = responseTimeMs / (i + 1);
        const msRestante = media * simulacoes.variacoes.length - responseTimeMs;

        if (msRestante > 86400000) {
          setProgressTime(
            Math.round(msRestante / 86400000) +
              (Math.round(msRestante / 86400000) > 1
                ? " remaining days"
                : " remaining day")
          );
        } else if (msRestante > 3600000) {
          setProgressTime(
            Math.round(msRestante / 3600000) +
              (Math.round(msRestante / 3600000) > 1
                ? " remaining hours"
                : " remaining hour")
          );
        } else if (msRestante > 60000) {
          setProgressTime(
            Math.round(msRestante / 60000) +
              (Math.round(msRestante / 60000) > 1
                ? " remaining minutes"
                : " remaining minute")
          );
        } else if (msRestante > 1000) {
          setProgressTime(
            Math.round(msRestante / 1000) +
              (Math.round(msRestante / 1000) > 1
                ? " remaining seconds"
                : " remaining second")
          );
        }

        resultados.push(res.data);

        setProgress(
          Math.round((i / simulacoes.variacoes.length) * 100 * 100) / 100
        );
      }

      setResponse({
        ...JSON.parse(JSON.stringify(input)),
        optimization: getBestpDieta(resultados),
      });
      setProgress(100);
      setAguarde(false);
      setOtimizado(true);
      setTab(1);
    } catch (e) {}
  };

  function getBestpDieta(resultados) {
    let lucroMaximo = 0;
    let lucro = 0;
    let response = {};
    resultados.forEach(function (item, index) {
      lucro = item.receita - item.gasto;
      if (lucro > lucroMaximo || index === 0) {
        lucroMaximo = JSON.parse(JSON.stringify(lucro));
        response = item;
        response.dadosDieta = pDietaOtimizacao(item.pDieta);
      }
    });
    response.ingredientesList = getListIngredientes(response.pDieta);
    return response;
  }

  function getListIngredientes(pDieta) {
    var list = [];
    pDieta.dietas.forEach(function (value) {
      value.dieta.ingredientes.forEach(function (item) {
        if (!checkThereIs(list, item.ingrediente, "_id")) {
          list.push({
            nome: getById(item.ingrediente, ingredients).nome,
            _id: item.ingrediente,
          });
        }
      });
    });
    return list;
  }

  function pDietaOtimizacao(item) {
    var dietas = [];
    item.dietas.forEach(function (item) {
      dietas.push(getComposicao(item.dieta, composicao, aminoacidos));
    });
    return dietas;
  }

  function getComposicao(dieta) {
    const ingredientesResult = [];
    let objetivoValue = 0;
    let percentual = 0;
    for (var i = 0; i < dieta.ingredientes.length; i++) {
      const ingrediente = getById(
        dieta.ingredientes[i].ingrediente,
        ingredients
      );
      ingredientesResult.push({
        nome: ingrediente.nome,
        _id: dieta.ingredientes[i].ingrediente,
        value: dieta.ingredientes[i].value,
        preco: (ingrediente.preco * dieta.ingredientes[i].value) / 100,
      });
      objetivoValue =
        objetivoValue + (ingrediente.preco * dieta.ingredientes[i].value) / 100;
      percentual = percentual + dieta.ingredientes[i].value;
    }

    return {
      ingredientesResult: ingredientesResult,
      objetivoValue: objetivoValue,
      percentual: percentual,
    };
  }

  function getById(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (id === array[i]._id) {
        return array[i];
      }
    }
    return [];
  }

  function checkThereIs(list, item, obj) {
    var thereIs = false;
    list.forEach(function (value) {
      if (value[obj] === item) {
        thereIs = true;
      }
    });
    return thereIs;
  }

  const saveOptimization = async () => {
    try {
      const query = JSON.parse(JSON.stringify(response));
      if (!input.name) {
        return Swal.fire(
          translate("Error", profile.language),
          translate("Type the optimization name", profile.language),
          "error"
        );
      }
      if (!input.data.customer) {
        query.data.customer = null;
      }

      if (params.id) {
        const responseOptimization = await Swal.fire({
          title: translate("Edit Optimization", profile.language),
          text: translate(
            "Do you want to confirm Optimization edit?",
            profile.language
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          cancelButtonText: translate("Cancel", profile.language),

          confirmButtonText: translate(
            "Yes, Edit Optimization",
            profile.language
          ),
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api.put("optimization/" + params.id, query).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
        });
        if (response.value) {
          Swal.fire(
            translate("Edit Optimization", profile.language),
            translate(
              responseOptimization.value.data.message,
              profile.language
            ),
            responseOptimization.value.err ? "error" : "success"
          );

          setGetData(true);
        }
      } else {
        const responseOptimization = await Swal.fire({
          title: translate("Create Optimization", profile.language),
          text: translate(
            "Do you want to confirm Optimization creation?",
            profile.language
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          cancelButtonText: translate("Cancel", profile.language),
          confirmButtonText: translate(
            "Yes, Create Optimization",
            profile.language
          ),
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api.post("optimization", query).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
        });

        if (responseOptimization.value) {
          Swal.fire(
            translate("Create Optimization", profile.language),
            translate(
              responseOptimization.value.data.message,
              profile.language
            ),
            responseOptimization.value.err ? "error" : "success"
          );
          navigate("/optimizator/edit/" + responseOptimization.value.data.id, {
            replace: true,
          });

          setGetData(true);
        }
      }
    } catch (e) {
      console.log(e);
      Swal.fire(
        translate("Save Optimization", profile.language),
        translate("Error saving Optimization", profile.language),
        "error"
      );
    }
  };

  useEffect(() => {
    const getInitData = async () => {
      setLoadingData(true);

      const responseCustomer = await api.post("filter/list", {
        model: "customer",
        sort: "name",
        select: "name",
      });
      setCustomers(responseCustomer.data);

      const responseIngredients = await api.post("filter/list", {
        model: "ingredient",
      });
      setIngredients(responseIngredients.data);

      const responseSimulations = await api.post("filter/list", {
        model: "simulation",
        sort: "nome",
        select: "nome customer simulationType",
        populate: {
          path: "dietProgram",
          select: "dietas",
          populate: {
            path: "dietas.dieta",
            select: "nome aminoacidos composicao",
          },
        },
      });
      setSimulations(responseSimulations.data);
      if (params.id) {
        const responseSimulation = await api.get("simulation/" + params.id);
        setInput(responseSimulation.data);
      }
      const responseNutrients = await api.get("nutrients");
      setAminoacidos(
        responseNutrients.data
          .filter(({ tipo }) => tipo === "a")
          .map(({ nome, nomeDB }) => ({ nome, nomeDB, valor: 0 }))
      );
      setComposicao(
        responseNutrients.data
          .filter(({ tipo }) => tipo === "c")
          .map(({ nome, nomeDB }) => ({ nome, nomeDB, valor: 0 }))
      );
      if (params.id) {
        const responseOptimization = await api.get("optimization/" + params.id);
        const { data, price, name, optimization } = responseOptimization.data;
        setInput({
          data,
          price,
          name,
        });
        setResponse({
          data,
          price,
          name,
          optimization,
        });
        setOtimizado(true);
      }
      setLoadingData(false);
    };
    if (getData) {
      setGetData(false);
      getInitData();
    }
  }, [getData, params.id]);

  return (
    <>
      <SubHeader
        {...props}
        title={translate(
          params.id ? "Edit Optimization" : "New Optimization",
          profile.language
        )}
        route="optimization"
        breadcrumbs={[
          { label: translate("Optimizator", profile.language) },
          {
            label: translate(
              params.id ? "Edit Optimization" : "New Optimization",
              profile.language
            ),
          },
        ]}
        otherButtons={
          otimizado
            ? [
                <Button
                  onClick={saveOptimization}
                  type="button"
                  bg="default"
                  border="default"
                  color="white"
                  style={{ width: "auto" }}
                >
                  {translate("Save Optimization", profile.language)}
                  &nbsp;{" "}
                  <FontAwesome
                    type="solid"
                    name="save"
                    size="12"
                    color="white"
                  />
                </Button>,
              ]
            : null
        }
        icon={
          <FontAwesome type="solid" name="spinner" size={15} color="text" />
        }
      />
      <Block>
        <BlockBody>
          {loadingData ? (
            <Loading
              style={{
                fill: "#094093",
                height: "24px",
                width: "24px",
                display: "inline-table",
              }}
              type="spin"
              color="#fff"
              height={24}
              width={24}
            />
          ) : (
            <>
              <Tabs
                value={tab}
                indicatorColor="default"
                textColor="default"
                variant="scrollable"
                scrollButtons="on"
                onChange={(e, value) => {
                  setTab(value);
                }}
              >
                <Tab label={translate("Input", profile.language)} />
                <Tab
                  label={translate("Result", profile.language)}
                  style={!otimizado ? { display: "none" } : null}
                />
              </Tabs>
              {tab === 0 &&
                (aguarde ? (
                  <ProgressBar
                    striped
                    variant="success"
                    now={progress}
                    label={
                      convertNumberToString(progress.toFixed(0)) +
                      "%, " +
                      progressTime
                    }
                  />
                ) : (
                  <InputData
                    {...{
                      customers,
                      simulations,
                      input,
                      setInput,
                      profile,
                      optimize,
                      dietComponents,
                    }}
                  />
                ))}
              {tab === 1 && (
                <Result
                  profile={profile}
                  response={response?.optimization}
                  ingredients={ingredients}
                  title="RESULT"
                  dietComponents={dietComponents}
                  input={input}
                />
              )}
            </>
          )}
        </BlockBody>
      </Block>
    </>
  );
}

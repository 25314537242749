import React from "react";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../Components/Table/style";
import { Separator } from "../../../../../../styles";
import {
  compareValues,
  convertNumberToString,
  translate,
} from "../../../../../../utils/globalFunctions";

export function Result({
  profile,
  response,
  ingredients,
  title,
  dietComponents,
  input,
}) {
  return (
    <>
      <TableContent className="table-center table-no-padding">
        <Header>
          <tr>
            <th colSpan={3}>
              {translate(
                `${title} - PERFORMANCE RESULTS AND ECONOMIC VALUES`,
                profile.language
              )}
            </th>
          </tr>
          <tr>
            <th>{translate("Diet Name", profile.language)}</th>
            <th>{translate("Feed Cost ($/kg)", profile.language)}</th>
            <th>{dietComponents[input.data.dComponent]}</th>
          </tr>
        </Header>
        <Body>
          {response.resPDieta.map(({ nome, valorDieta, nNutriente }) => (
            <tr key={nome}>
              <th>{nome}</th>
              <td>{convertNumberToString(valorDieta, 2)}</td>
              <td>{convertNumberToString(nNutriente, 3)}</td>
            </tr>
          ))}
        </Body>
      </TableContent>
      <Separator />
      <TableContent className="table-center table-no-padding">
        <Header>
          <tr>
            <th colSpan={3}>
              {translate("Otimized Diet Program", profile.language)}
            </th>
          </tr>
          <tr>
            <th>{translate("Ingredients", profile.language)}</th>
            {response.resPDieta.map(({ nome }, index) => (
              <th key={index}>{nome} (%)</th>
            ))}
          </tr>
        </Header>
        <Body>
          {response.ingredientesList
            .map(({ _id }) => ({
              _id,
              name: ingredients.filter((item) => item._id === _id)[0].nome,
            }))
            .sort(compareValues("name", "asc"))
            .map(({ _id, name }) => (
              <tr key={_id}>
                <th>{name}</th>
                {response.resPDieta.map(({ ingredientes }, index) => (
                  <td key={index}>
                    {convertNumberToString(
                      ingredientes.filter(
                        ({ ingrediente }) => ingrediente === _id
                      )[0]?.value,
                      3
                    )}
                  </td>
                ))}
              </tr>
            ))}
          <tr>
            <th>Total</th>
            {response.resPDieta.map(({ ingredientes }, index) => (
              <th key={index}>
                {convertNumberToString(
                  ingredientes
                    .map(({ value }) => value)
                    .reduce((a, b) => a + b),
                  3
                )}
              </th>
            ))}
          </tr>
        </Body>
      </TableContent>
    </>
  );
}
